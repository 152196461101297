<template>
    <div class="flex align-items-center justify-content-between flex-wrap">
        <h4 class="font-bold mb-5 text-gray-800 hover:text-primary">Secciones de Revisión</h4>
        <div class="my-1">
            <Button type="button" icon="pi pi-plus" label="Nuevo" class=" p-button-outlined" @click="upsertSeccion_onClick()"/> 
        </div>
    </div>
    <div class="font-normal text-base mb-3">
        <p>Las secciones  de revisión son utilizadas para segementar los criterios de la evaluación en la plantilla a utilizar como esquema activo.
        <a href="javascript:;" class="text-primary">Plantilla de evaluación</a></p>
    </div>
    <div class="mb-5">
        <SeccionData :Service="Service" @edit_onEmit="upsertSeccion_onClick($event)" :key="seccionDataKey"/>
    </div>
        <div class="font-normal text-base mb-3">
        <p class="text-danger">* Si se actualiza la descripción de uno o más secciones este afectará el histórico. </p>
    </div>

     <SeccionUpsert :Service="Service" :value="seccionModel" v-model:Upsert="upsertMode"  @hide="seccion_onHide($event)" :key="upsertSeccionKey"/>
</template>




<script>
import SeccionData from "@/components/LaborDocente/Revision/SeccionData"
import SeccionUpsert from "@/components/LaborDocente/Revision/SeccionUpsert"



export default {
    components:{
        SeccionData,
        SeccionUpsert
    },
    props:{
        Service:null
    },
    emits:[],
    data(){

        return  {
            escalaModel : {},
            upsertMode: false,


            upsertSeccionKey: 0,
            seccionDataKey: 0
        }
    },
    created(){
        this.seccionModel =   {
            rse_codigo :  0,
            rse_nombre : "",
            rse_activo: false
        }
    },
    mounted(){},
    methods:{

        seccion_onHide(event){
            this.upsertMode = false
            if(event != null) this.seccionDataKey += 1;

        },
        upsertSeccion_onClick(data = null){
            if(data == null)
                this.seccionModel =   {
                    rse_codigo :  0,
                    rse_nombre : "",
                    rse_crit_desempeno : "",
                    rse_competencia :  "",
                    rse_activo: false
                }
            else{
                this.seccionModel =  data
            }
            this.upsertSeccionKey += 1; // force render
            this.upsertMode =  true;
        }


    },
    computed:{
       
    }
}
</script>