<template>
    <div class="flex flex-column md:flex-row justify-content-end align-items-center mb-2">
        <span class="p-input-icon-left  p-fluid" >
            <i class="pi pi-search" />
            <InputText  placeholder="Buscar criterio"   v-model="filters1['global'].value"/>
        </span>
    </div>
    <DataTable :value="criterioList" responsiveLayout="scroll" :rowHover="true" :rows="30" class="p-datatable-sm"
        v-model:selection="criterioSelected" :selectionMode="Selection" dataKey="rce_codigo"
        :paginator="true" paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport  RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} "
        :rowsPerPageOptions="[30,50,100]"
        :globalFilterFields="['rce_enunciado']" v-model:filters="filters1">
        <template #loading>
            Cargando criterios, por favor espere...
        </template>
        <template #empty>
            No hay criterios en la base de datos.
        </template>
        <Column field="rce_codigo" sortable header="Cód.">
            <template #body="{data}"> C{{data.rce_codigo}}
            </template>
        </Column>
        <Column field="rce_enunciado" header="Enunciado"/>
        <Column  header="">
            <template #body="{data}">
                 <a href="javascript:void(0)" class="label label-lg label-inline font-bold ml-2 py-0 my-0" :class="'label-light-'+ (data.rce_activo ? 'primary' : 'danger')">{{data.rce_activo ? "Activo" : "Inactivo"}}</a>
            </template>
        </Column>
        <Column v-if="EditMode">
            <template #body="{data}">
                <div class="flex justify-content-end flex-shrink-0">
                    <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="edit_onClick(data)" v-tooltip.top="'Editar'" > 
                        <i class="pi pi-pencil"></i>
                    </a>
                </div>
            </template>
        </Column>

    </DataTable>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
export default{
    props:{
        Service: null,
        EditMode:{
            type:Boolean,
            default: true
        },
        Selection: {
            type:String,
            default : null
        },
        ItemSelected:{
            type: Object,
            default(){
                return {}
            }
        }
    },
    emits:["edit_onEmit",'update:ItemSelected'],
    data(){
        return{
            criterioList: [],
            criterioSelected: this.ItemSelected,
            filters1: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
             }
        }
    },
    created(){},
    async mounted(){
        this.criterioList = await this.Service.getAllCriterio();
    },
    methods:{
        edit_onClick(data){
            this.$emit("edit_onEmit", data)
        }
    },
    computed:{},
    watch:{
        // eslint-disable-next-line no-unused-vars
        criterioSelected: function(val){
           this.$emit('update:ItemSelected', val)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>