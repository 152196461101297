<!--This Dialogo for upsert  Escala -->
<template>
    <Dialog v-model:visible="isCreateSeccion" :modal="true" :style="{width: '460px'}" @hide="$emit('hide', null);">
        <template #header>
            <h4 class="font-semibold text-gray-900">Sección</h4>
        </template>
        <div class="p-fluid">
            <div class="field">
                <label for="rse_nombre">Nombre</label>
                <Textarea  id="rse_nombre"  v-model.trim="seccionModel.rse_nombre" required="true" rows="2" 
                    :class="{'p-invalid': submitted && !seccionModel.rse_nombre}"  :autoResize="true"   placeholder="" />
                <small class="p-error" v-if="submitted && !seccionModel.rse_nombre">*Nombre Sección.</small>
            </div>
        </div>
        <div class="p-fluid">
            <div class="field">
                <label for="rse_nombre">Competencia</label>
                <Textarea  id="rse_nombre"  v-model.trim="seccionModel.rse_competencia" required="true" rows="2"  :autoResize="true"   placeholder="" />
                <div class="flex justify-content-end" :class="{'text-danger': (seccionModel.rse_competencia.length > 500)}">
                    <small>{{seccionModel.rse_competencia.length}}/500</small>
                </div>
            </div>
        </div>
        <div class="p-fluid">
            <div class="field">
                <label for="rse_nombre">Criterio</label>
                <Textarea  id="rse_nombre"  v-model.trim="seccionModel.rse_crit_desempeno" required="true" rows="2"  :autoResize="true"   placeholder="" />
                 <div class="flex justify-content-end" :class="{'text-danger': (seccionModel.rse_crit_desempeno.length > 500)}">
                    <small>{{seccionModel.rse_crit_desempeno.length}}/500</small>
                </div>
            </div>
        </div>
        <div class="flex-none flex align-items-center justify-content-end">
            <div class="field-checkbox">
                <label for="rce_activo">Activo</label> 
                <InputSwitch  id="rce_activo" class="ml-2" v-model="seccionModel.rse_activo" :binary="true"/> 
                
            </div>
        </div>
        <template #footer>
            <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="isCreateSeccion =  false" />
            <Button type="submit" label="Guardar" class="mt-2 " @click="save_onClick()" />
        </template>
    </Dialog>
</template>

<script>
export default {
    props:{
        Upsert:{
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default(){
                return {}
            }
        },
        Service: null
    },
    emits:["hide"],
    data(){
        return{
            seccionModel : { ... this.value },
            submitted : false,
            isCreateSeccion : this.Upsert
        }
    },
    created(){},
    mounted(){},
    methods:{
        async save_onClick(){
            try{
                this.submitted  =  true

                if (!this.seccionModel.rse_nombre || !this.seccionModel.rse_nombre.trim()){
                    return;
                }

                await this.Service.upsertSeccion(this.seccionModel).then(res => {
                    this.$emit("hide", res)
                    this.$toast.add({severity:'info', summary:'¡Registrado!', detail:"El dato fue registrado con éxito", life: 3000});
                    this.isCreateSeccion =  false
                });
            }
            catch(ex){
                var msg = ex;
                if(msg.response)
                    msg =  msg.response.data
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 3000});
            }
        }
    }, 
    computed:{},
}
</script>