<template>
     <div class="flex align-items-center justify-content-between flex-wrap">
        <h4 class="font-bold mb-5 text-gray-800 hover:text-primary">Criterios de Revisión</h4>
        <div class="my-1">
            <Button type="button" icon="pi pi-plus" label="Nuevo" class=" p-button-outlined" @click="upsertCriterio_onClick()"/> 
        </div>
    </div>
    <div class="font-normal text-base mb-3">
        <p>Las criterios de evaluación para una revisión tienen las escalas según grupo
        <a href="javascript:;" class="text-primary">Escalas de evaluación</a>.  </p>
    </div>
    <div class="mb-5">
        <CriterioData :Service="Service"  @edit_onEmit="upsertCriterio_onClick($event)" :key="criterioDataKey"/>
    </div>
        <div class="font-normal text-base mb-3">
        <p class="text-danger">* Si se actualiza el enunciado de un criterio este afectará el historico, se recomienda crear nuevo criterio y desactivar. </p>
    </div>

    <CriterioUpsert :Service="Service" v-model:Upsert="upsertMode" :value="criterioModel" @hide="criterio_onHide($event)" :key="upsertCriterioKey" />
    
</template>

<script>
import CriterioData from "@/components/LaborDocente/Revision/CriterioData"
import CriterioUpsert from "@/components/LaborDocente/Revision/CriterioUpsert"
export default{
    components:{ 
        CriterioData,
        CriterioUpsert
    },
    props:{
        Service: null
    },
    emits:[],
    data(){
        return {
            criterioModel: {},
            upsertCriterioKey : 0, // Force Render
            criterioDataKey :  0,
            upsertMode : false
        }
    },
    created(){
        this.criterioModel = {
            rce_codigo  : 0,
            rce_enunciado :  "",
            rce_activo : false,
            escalaList : []
        }
    },
    async mounted(){},
    methods:{
        criterio_onHide(event){
            this.upsertMode = false
            if(event != null) this.criterioDataKey += 1;
        },
        upsertCriterio_onClick(data = null){
            if(data == null)
                this.criterioModel = {
                    rce_codigo  : 0,
                    rce_enunciado :  "",
                    rce_activo : false,
                    escalaList: []
                }
            else{
                this.criterioModel =  data
            }
            this.upsertCriterioKey += 1; 
            this.upsertMode =  true;
        }
    },
    computed:{}

}
</script>   