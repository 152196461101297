<template>
        <div class="flex flex-column md:flex-row justify-content-end align-items-center mb-2">
            <span class="p-input-icon-left  p-fluid" >
                <i class="pi pi-search" />
                <InputText  placeholder="Escala, Grupo"   v-model="filters1['global'].value"/>
            </span>
        </div>
        <DataTable :value="escalaList" responsiveLayout="scroll" :rowHover="true" :rows="20" class="p-datatable-sm"
            v-model:selection="escalaSelected" :selectionMode="Selection" dataKey="ree_codigo"
            :paginator="true" paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport  RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} "
            :rowsPerPageOptions="[30,50,100]"
            :globalFilterFields="['ree_descripcion', 'ree_agrupador']" v-model:filters="filters1">
            <template #loading>
                Cargando escalas, por favor espere...
            </template>
            <template #empty>
                No hay escalas en la base de datos.
            </template>
            <Column header="Cód." field="ree_codigo"   sortable headerStyle="width:4rem">
                <template #body="{data}">E{{data.ree_codigo}}
                </template>
            </Column>
            <Column field="ree_descripcion" sortable header="Descripcion">
                <template #body="{data}">
                    <div class="flex flex-column">
                        <span>{{data.ree_descripcion}}</span>
                        <div class="flex justify-content-end"> 
                            <a href="javascript:void(0)" class="label label-lg text-xs  label-light-primary label-inline font-bold ml-2 py-0 my-0" >{{data.ree_agrupador}}</a>
                        </div>
                    </div>
                </template>
            </Column>
            <Column  v-if="EditMode">
                <template #body="{data}">
                    <div class="flex justify-content-end flex-shrink-0">
                        <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="edit_onClick(data)" v-tooltip.top="'Editar'" > 
                            <i class="pi pi-pencil"></i>
                        </a>
                    </div>
                </template>
            </Column>

        </DataTable>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
export default {
    props:{
        Service: null,
        EditMode:{
            type:Boolean,
            default: true
        },
        Selection: {
            type:String,
            default : null
        },
        ItemSelected:{
            type: Object,
            default(){
                return {}
            }
        }
    },
    emits: ["edit_onEmit",'update:ItemSelected'],
    data(){
        return{
            escalaList: [],
            escalaSelected: this.ItemSelected,
            filters1: {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
    },
    created(){},
    async mounted(){
        this.escalaList =  await  this.Service.getAllEscala();

    },
    methods:{
        edit_onClick(data){
            this.$emit("edit_onEmit", data)
        }
    },
    watch:{
        // eslint-disable-next-line no-unused-vars
        escalaSelected: function(val){
           this.$emit('update:ItemSelected', val)
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>