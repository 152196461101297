<!--This Dialogo for upsert  Escala -->
<template>
    <Dialog v-model:visible="isCreateEscala" :modal="true" :style="{width: '460px'}" @hide="$emit('hide', null);">
        <template #header>
            <h4 class="font-semibold text-gray-900">Escala</h4>
        </template>
        <div class="flex sm:flex-column flex-row">
            <div class="grid p-fluid">
                <div class="col-12 md:col-3">Tipo:</div>
                <div v-for="type of escalaTypes" :key="type.key" class="col-12 md:col-3">
                    <div  class="field-radiobutton mt-1">
                        <RadioButton :id="type.key" name="category" :value="type.key" v-model="selected.type"  />
                        <label :for="type.key">{{type.name}}</label>
                    </div>
                </div>
            </div>
             <Divider class="my-2"/>
            <div class="grid p-fluid">
                <div class="col-12 md:col-4">Grupo:</div>
                <div v-for="group of escalaGroup" :key="group.key" class="col-12 md:col-4">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <RadioButton  :id="group.key" name="group" :value="group.key" v-model="selected.group" />
                            <label :for="group.key" class="ml-2">{{group.key}}</label>
                        </span>
                        <InputText placeholder="INS" v-model="selected.text" v-if="group.key != 'DEFAULT'"  :class="{'p-invalid': submitted && !selected.text.trim()}"/>
                    </div>
                </div>
            </div>
        </div>
        <Divider class="my-2"/>
        
        <div class="flex justify-content-end">
            <h5 class="text-base font-bold text-gray-700"><i class="pi pi-tag"/> {{agrupadorCode}}</h5>
        </div>
        <div class="p-fluid">
            <div class="field">
                <label for="ree_descripcion">Descripción</label>
                <Textarea  id="ree_descripcion" class="text-center" v-model.trim="escalaModel.ree_descripcion" required="true" rows="2" 
                    :class="{'p-invalid': submitted && !escalaModel.ree_descripcion}"  :autoResize="true"   placeholder="Descripción escala" />
                <small class="p-error" v-if="submitted && !escalaModel.ree_descripcion">* Descripción escala.</small>
            </div>
        </div>
        <template #footer>
            <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="isCreateEscala =  false" />
            <Button type="submit" label="Guardar" class="mt-2 " @click="save_onClick()" />
        </template>
    </Dialog>
</template>

<script>
export default {
    props:{
        Upsert:{
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default(){
                return {}
            }
        },
        Service: null
    },
    emits:["hide", "update:escalaModel"],
    data(){
        return{
            escalaModel : { ... this.value },
            escalaTypes:[ 
                { name: "Lógico", key: "LOGICO"},
                { name: "Multiple", key: "MULTI"},
                { name: "Mixto", key: "MIXTO"},
            ],
            selected:{ //Seleccion para el agrupador 
                type : "",
                group : "",
                text : ""
            },
            escalaGroup:[
                { name: "Default", key: "DEFAULT"},
                { name: "Personalizado", key: "REV"}
            ],

            submitted : false,
            isCreateEscala : this.Upsert
        }
    },
    created(){},
    mounted(){
        //get codigo agrupador
        if(this.escalaModel.ree_agrupador.trim()){
            var splitGroup =  this.escalaModel.ree_agrupador.split("_");
            this.selected  = {  
                    type : splitGroup[0],
                    group : splitGroup[1],
                    text : splitGroup[2] != undefined ?  splitGroup[2] : ""
                }
        }
        
    },
    methods:{
        async save_onClick(){
            try{
                this.submitted  =  true

                if(!this.selected.type || !this.selected.type.trim()){
                    throw "Seleccione un tipo válido."
                }
                else if((this.selected.group != "DEFAULT" && !this.selected.text.trim()) ||  !this.selected.group.trim()){
                   throw "Grupo necesita indicador."
                }
                else if (!this.escalaModel.ree_descripcion || !this.escalaModel.ree_descripcion.trim()){
                    return;
                }

                this.escalaModel.ree_agrupador =  this.agrupadorCode;
                await this.Service.upsertEscala(this.escalaModel).then(res => {
                    this.$emit("hide", res)
                    this.$emit("update:escalaModel", res);
                    this.$toast.add({severity:'info', summary:'¡Registrado!', detail:"Registrado correctamente", life: 3000});
                    this.isCreateEscala =  false
                });
            }
            catch(ex){
                var msg =  ex;
                if(msg.response)
                    msg =  msg.response.data
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 3000});
            }
        }
    }, 
    computed:{
        agrupadorCode(){
            var sub = `${this.selected.type}_${this.selected.group}`
            sub += this.selected.group != "DEFAULT" ? `_${this.selected.text}` : ""; 
            return sub
        }
    },
}
</script>