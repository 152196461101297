<template>
    <div class="grid">
		<div class="col-12 md:col-12 lg:col-10 xl:col-10 xl:col-offset-1  lg:col-offset-1">
            <Toolbar class="border-round">
                <template #left>
                    <div class="flex align-items-center justify-content-center">
                        <span class="text-lg  font-semibold">Esquema </span>
                        <Breadcrumb  :home="null" :model="breadcrumbModel"  class="hidden md:inline"/>
                    </div>
                </template>
                <template #right>
                    
                </template>
            </Toolbar >
            <div class="p-card my-1">
                <div class="p-card-body">
                    <div class="grid">
                        <div class="col-12 lg:col-4 xl:col-3">
                            <ul class="navi navi-link-rounded navi-accent navi-primary navi-hover mb-3 md:mb-0">
                                <li class="navi-item " v-for="(tab, index) in tabValues" :key="index">
                                    <a class="navi-link" :class="{'active': d_activeIndex == index}" @click="onTabClick($event, index)"> 
                                        <span class="navi-text text-lg font-bold mb-0">{{tab}}</span>    
                                    </a>
                                </li>
                            </ul>
                           
                        </div>
                        <!-- ESQUEMA -->
                        <div class="col-12 lg:col-8 xl:col-9">
                            <PlantillaPanel  :Service="revisionEsquemaService" :Ins="1" v-if="d_activeIndex == 0" />
                            <PlantillaPanel  :Service="revisionEsquemaService" :Ins="3" v-if="d_activeIndex == 1" />
                            <PlantillaPanel  :Service="revisionEsquemaService" :Ins="4" v-if="d_activeIndex == 2" />
                            <PlantillaPanel  :Service="revisionEsquemaService" :Ins="6" v-if="d_activeIndex == 3" />
                            <!--Begin: Sección -->
                            <SeccionPanel  :Service="revisionEsquemaService" v-if="d_activeIndex == 4"  />
                            <!--Begin: Criterio -->
                            <CriterioPanel  :Service="revisionEsquemaService" v-if="d_activeIndex == 5" />
                            <!--End: Criterio -->
                            <!--Begin: Escala -->
                            <EscalaPanel  :Service="revisionEsquemaService" v-if="d_activeIndex == 6" />
                            <!--End: Escala -->
                            
                            <div class="mt-2">
                                <div class="grid">
                                    <div class="col-12 lg:col-4">
                                        <a href="javascript:void(0)" class="card wave wave-animate-slow wave-primary md:mb-4 lg:mb-0" @click="onTabClick($event, 1)">
                                            <div class="card-body">
                                                <div class="flex align-items-center p-2">
                                                    <div class="mr-4">
                                                        <ClipboardCheckIco :size="'svg-icon-4x'" />
                                                    </div>
                                                    <div class="flex flex-column">
                                                        <h5 class="mb-1 text-gray-700 font-semibold">Secciones</h5>
                                                        <div class="text-muted font-medium" >Área de desempeño</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                     <div class="col-12 lg:col-4">
                                        <a href="javascript:void(0)" class="card wave wave-animate wave-primary md:mb-4 lg:mb-0" @click="onTabClick($event,2)">
                                            <div class="card-body">
                                                <div class="flex align-items-center p-2">
                                                    <div class="mr-4">
                                                        <CompilingIco :size="'svg-icon-4x'" /> 
                                                    </div>
                                                    <div class="flex flex-column">
                                                        <h5 class="mb-1 text-gray-700 font-semibold">Criterio</h5>
                                                        <div class="text-muted font-medium" >Evaluación</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                     <div class="col-12 lg:col-4">
                                        <a href="javascript:void(0)" class="card wave wave-animate-fast wave-primary md:mb-4 lg:mb-0" @click="onTabClick($event, 3)">
                                            <div class="card-body">
                                                <div class="flex align-items-center p-2">
                                                    <div class="mr-4">
                                                        <PixelIco :size="'svg-icon-4x'" />
                                                    </div>
                                                    <div class="flex flex-column">
                                                        <h5 class="mb-1 text-gray-700 font-semibold">Escalas</h5>
                                                        <div class="text-muted font-medium" >Evaluación</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import EscalaPanel from "@/components/LaborDocente/Revision/EscalaPanel"
import CriterioPanel from "@/components/LaborDocente/Revision/CriterioPanel"
import SeccionPanel from "@/components/LaborDocente/Revision/SeccionPanel"
import PlantillaPanel from "@/components/LaborDocente/Revision/PlantillaPanel"
import {ClipboardCheck, Compiling, Pixel} from "@/components/svg/_svgHelper"

import RevisionEsquemaService from "@/service/revisionEsquemaService"

export default {
    revisionEsquemaService : null,
    components:{
        EscalaPanel,
        CriterioPanel,
        SeccionPanel,
        PlantillaPanel,
        "ClipboardCheckIco" :  ClipboardCheck,
        "CompilingIco" : Compiling,
        "PixelIco" : Pixel
    },

    data(){
        return {
            breadcrumbModel :[],
            d_activeIndex: 0,
            tabValues: ["Esquema de revisión","Esquema Guía Observación", "Esquema Escala Desempeño (EEDD)","Esquema Desarrollo y Rendimiento (EDRE)", "Sección/Area desempeño","Criterios","Escalas"],
        }
        
    },
    created(){
        this.breadcrumbModel = [ 
            { label: this.$store.state.uConfig.cfCurrentModule.mod_nombre, to: '#'},
            { label: "Revisión", to: '#'},
            { label: this.$store.state.uConfig.cfCurrentMenu.men_descripcion, to: '#'},
        ]
        
        this.revisionEsquemaService =  new RevisionEsquemaService(this.axios);
    },

    methods:{
        async onTabClick(event, i) {
            if (i !== this.d_activeIndex) {
                this.d_activeIndex = i;
            }
        },
    },

    computed:{
    }
}
</script>

<style scoped>
    /*Navi*/
    
</style>