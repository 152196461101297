// JSDoc (http://usejsdoc.org/)

/** @module ParametroService */

/** 
 * @class

*/
export default class ParametroService {
    /**
    * @param axios Axios Instance
    */
    constructor(axios) {
       this.axios = axios;
    }

    async getNivelDominio(version){
        return this.axios.get(`/Parametro/GetNivelDominio/'${version}'`).then(res => res.data);
        
    }
}