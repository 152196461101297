<!-- This dialog for Criterio -->
<template>
    <Dialog v-model:visible="isCreateCriterio" :modal="true" :style="{width: '520px'}" @hide="$emit('hide',submitted ? true : null)">
        <template #header>
            <h4 class="font-semibold text-gray-900">Criterio</h4>
        </template>
        
        <div class="p-fluid">
            <div class="field">
                <label for="isCreateCriterio">Enunciado / S{{criterioModel.rce_codigo}} </label>
                <Textarea  id="isCreateCriterio"  v-model.trim="criterioModel.rce_enunciado" required="true" rows="3" 
                    :class="{'p-invalid': submitted && !criterioModel.rce_enunciado}"  :autoResize="true"   placeholder="Enunciado de criterio" />
                <small class="p-error" v-if="submitted && !criterioModel.rce_enunciado">*Enunciado criterio.</small>
            </div>
        </div>
        <div class="flex-none flex align-items-center justify-content-between my-1">
            <div class="w-10rem">
                <div class="p-inputgroup">
                    <InputText placeholder="Orden" class="p-inputtext-sm" v-model="criterioModel.rec_cri_orden"/>
                    <Button icon="pi pi-check" class="p-button-danger" @click="setOrden_OnClick()"/>
                </div>
                <small class="p-error" v-if="submitted && criterioModel.rec_cri_orden == 0">* Orden no válido</small>
            </div>
            
            <div class="field-checkbox">
                <label for="rce_activo">Activo</label> 
                <InputSwitch  id="rce_activo" class="ml-2" v-model="criterioModel.rce_activo" :binary="true"/> 
                
            </div>
        </div>
        <DataTable :value="criterioModel.escalaList" editMode="row" dataKey="rec_codigo" v-model:editingRows="editingEscala" 
        
        @row-edit-init="rowEdit_onInit" 
        @row-edit-cancel="rowEdit_onCancel" 
         responsiveLayout="scroll">
            <template #loading>
                Cargando escalas, por favor espere...
            </template>
            <template #empty>
                No hay escalas en este criterio.
            </template>
            <Column field="escala.ree_descripcion"  header="Escala">
                <template #body="slotProps">
                    <div class="flex align-items-center ">
                        <a href="javascript:;" class="flex align-items-center" v-tooltip.top="'Eliminar'"  @click="cancelCritEsc_onClick(slotProps.index,slotProps.data )"> <i class="pi pi-minus-circle text-danger" /></a>
                        <div class="h-100 p-0 mr-1 bg-warning" style="width:2px" v-if="slotProps.data.rec_codigo == 0"></div>
                        <div class="flex-grow-1 mx-2">
                            <span>{{slotProps.data.escala.ree_descripcion}}</span> 
                            <small class="text-xs text-danger">({{slotProps.data.rec_esc_orden}})</small>
                        </div>
                    </div>
                </template>
                <template #editor="{data, index}">
                    <div class="flex ">
                        <a href="javascript:;" v-if="data.rec_codigo == 0"  @click="cancelCritEsc_onClick(index,data, true)"> <i class="pi pi-times text-warning" /></a>
                        <div class="h-100 p-0 mr-1 bg-warning" style="width:2px" v-if="data.rec_codigo == 0"></div>
                      
                        <div class="flex flex-grow-1 ml-1 flex-column justify-content-center">
                            {{data.escala.ree_descripcion}}</div>
                    </div>
                    <div class="w-100 p-0 my-2 bg-danger" style="height:2px"></div>
                    <div class="flex justify-content-end align-items-center">
                        <!-- <InputText v-model="data['rec_cri_orden']" autofocus class="w-5rem text-center"/> -->
                        <div class="formgroup-inline">
                            <div class="field">
                                <label for="rec_esc_orden">Orden</label>
                                <InputText id="rec_esc_orden" v-model="data['rec_esc_orden']" autofocus class="w-5rem text-center"/>
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
            <!-- <Column field="rec_cri_orden" header="Ord. Criterio"  style="max-width:5rem">
                <template #editor="{data}">
                    <InputText v-model="data['rec_cri_orden']" autofocus class="w-5rem text-center"/>
                </template>
            </Column>
            <Column field="rec_esc_orden" header="Ord. Escala"  style="max-width:5rem">
                <template #editor="{data,column }">
                   
                </template>
            </Column> -->
            <Column :rowEditor="true" style="min-width:8rem; width:8rem" bodyStyle="text-align:center">
                <template #header class="flex align-items-end">
                    <Button icon="pi pi-search" class="p-button-rounded p-button-primary p-button-outlined" @click="isDialogEscala = true"/>
                </template>
            </Column>
        </DataTable>
            
        <template #footer>
            <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="isCreateCriterio =  false; $emit('hide',submitted ? true : null)" />
            <Button type="submit" :label="criterioModel.rce_codigo > 0 ? 'Aplicar Cambios' : 'Guardar'" class="mt-2 " @click="save_onClick()" />
        </template>
    </Dialog>


    <Dialog v-model:visible="isDialogEscala" :modal="true" :style="{width: '520px'}">
        <template #header>
            <h4 class="font-semibold text-gray-900">Buscar escala</h4>
        </template>
        <div class="my-2">
            <EscalaData :Service="Service"  :EditMode="editMode" :Selection="'multiple'" v-model:ItemSelected="escalaSelected" />
        </div>

        <template #footer>
            <div class="flex justify-content-between">
                <Button icon="pi pi-plus-circle" class="p-button-rounded p-button-success p-button-text w-1 h-1" v-tooltip.top="'Nuevo'" @click="addEscala_onClick()" />
                <div class="flex">
                    <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="isDialogEscala =  false; escalaSelected =  null" />
                    <Button type="submit" label="Seleccionar" class="mt-2 " @click="escala_onSelected()" />
                </div>
            </div>
        </template>
    </Dialog>

    <EscalaUpsert :Service="Service" v-model:value="escalaModel" v-model:Upsert="escalaUpsert"  @hide="addEscala_onHide($event)" v-if="escalaUpsert" />
</template>

<script>

import EscalaData from "@/components/LaborDocente/Revision/EscalaData"
import EscalaUpsert from "@/components/LaborDocente/Revision/EscalaUpsert"

export default{
    components:{
        EscalaData,
        EscalaUpsert
    },
    props:{
        value:{
            type: Object,
            default(){
                return {}
            }
        },
        Upsert:{
            type:Boolean,
            default: false
        },
        Service : null
    },
    emits:["hide"],
    data(){
        return{
            criterioModel: {...this.value},
            editingEscala : [],
            escalaSelected: null,
            oldValueEdit : [],
            submitted:  false,
            isCreateCriterio :  this.Upsert,
            isDialogEscala :  false,
            editMode: false,


            //for upsert Escala
            escalaModel : {},
            escalaUpsert  : false
        }
    },
    created(){
        this.escalaModel =   {
            ree_codigo :  0,
            ree_descripcion : "",
            ree_agrupador:  ""
        }
    },
    async mounted(){
        this.criterioModel["rec_cri_orden"] = 0; //set orden criterio

        if(this.criterioModel.rce_codigo != 0) // nuevo
        {
            //get all: Escala
            this.criterioModel.escalaList = await this.Service.getAllCriterioEscala(this.criterioModel.rce_codigo);
            
            //set: valor de orden de criterio global
            if(this.criterioModel.escalaList.length > 0)
                this.criterioModel.rec_cri_orden =  this.criterioModel.escalaList[0].rec_cri_orden;

            //set: agregar objeto escala a la la lista criterio/escala 
            if(this.criterioModel.escalaList.escala == undefined)
                this.criterioModel.escalaList["escala"] = []
        }
    },
    methods:{
        //Agregar una escala desde el buscador
        addEscala_onClick(){
            this.escalaModel =   {
                ree_codigo :  0,
                ree_descripcion : "",
                ree_agrupador:  ""
            }

            this.isDialogEscala   = false; 
            this.escalaUpsert =  true;
        },
        //hide: al salir de la pantalla de buscador de escala
        addEscala_onHide(event){
            this.escalaUpsert = false
            //event: return emit -> event.<Model>
            if(event != null) 
            { 
                var  escalaLength = this.criterioModel.escalaList.length;
                var newValue = {
                    rec_codigo: 0,
                    rec_codrce: this.criterioModel.rce_codigo,
                    rec_codree: event.ree_codigo,
                    rec_cri_orden : this.criterioModel.rec_cri_orden,
                    rec_esc_orden: escalaLength + 1,
                    escala : event
                }

                this.criterioModel.escalaList.push(newValue)
            }


        },
        rowEdit_onInit(event){
            let { data, index } = event
            this.oldValueEdit[index] =  {...data}
        },
         rowEdit_onCancel(event){
            let {index } = event
            this.criterioModel.escalaList[index] = {...this.oldValueEdit[index]}
            delete this.oldValueEdit[index]
        },
        //Guardar el registro
        async save_onClick(){
            try{
                this.submitted  =  true
                if (this.editingEscala.length > 0)
                {
                    throw "Existe una fila en modo de edición aplique o cancele el cambio."
                }
                if (!this.criterioModel.rce_enunciado || !this.criterioModel.rce_enunciado.trim()){
                    return;
                }

                if(this.criterioModel.rec_cri_orden == 0){
                    return;
                }

                await this.Service.upsertCriterio({
                    criterio : this.criterioModel,
                    revCriterioEscala : this.criterioModel.escalaList
                }).then(res => {
                    res["rec_cri_orden"] = this.criterioModel.rec_cri_orden 
                    this.criterioModel =  res
                });
                this.$toast.add({severity:'info', summary:'¡Registrado!', detail:"El dato fue registrado con éxito", life: 3000});
            }
            catch(ex){
                var msg = ex;
                if(msg.response)
                    msg =  msg.response.data
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }
        },

        async cancelCritEsc_onClick(index, data = null){
            //criIdx: Index de criterio
            this.$confirm.require({
                message: `¿Está seguro de eliminar esta escala de este criterio?`,
                header: 'Eliminar Escala',
                icon: 'pi pi-info-circle',
                acceptLabel: 'Sí, Eliminar',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    try{
                        if(data.rec_codigo != 0){
                            var message ="";
                            await this.Service.deleteCriterioEscala(data.rec_codigo).then(res  => message = res)
                            this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                        }
                        this.criterioModel.escalaList.splice(index,1)
                    }catch(ex){
                        var msg =  ex;
                        if(msg.response)
                            msg= msg.response.data;

                        this.$toast.add({severity:'error', summary:'¡Ocurrió un error!', detail:msg, life: 3000});
                    }
                },
            });
        },
        setOrden_OnClick(){
            if(this.criterioModel.escalaList.length == 0)
                return;

            this.criterioModel.escalaList.forEach(element => {
                element.rec_cri_orden = this.criterioModel.rec_cri_orden
            });
        },
        escala_onSelected(){
            this.isDialogEscala  =  false;

            //Cantidad de escalas
            var  escalaLength = this.criterioModel.escalaList.length;

            this.escalaSelected.forEach(item => {
                escalaLength++ // Sig. orden de escala
                var newValue = {
                    rec_codigo: 0,
                    rec_codrce: this.criterioModel.rce_codigo,
                    rec_codree: item.ree_codigo,
                    rec_cri_orden : this.criterioModel.rec_cri_orden,
                    rec_esc_orden: escalaLength,
                    escala : item
                }

                this.criterioModel.escalaList.push(newValue)
            });
            
           

        }
    },
}
</script>
<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}
</style>