<template>
    <div class="flex align-items-center justify-content-between flex-wrap">
        <h4 class="font-bold mb-5 text-gray-800 hover:text-primary">{{instrumento[Ins]}}</h4>
        <div class="my-1">
            <span class="p-buttonset">
                <Button type="button" icon="pi pi-plus" label="Nuevo" class=" p-button p-button-outlined" @click="upsertEsquema_onClick()"/> 
                <Button type="button" icon="pi pi-print" :loading="isloadingPrint" class=" p-button p-button-outlined" style="padding:0.65rem" @click="printEsquema_onClick()"/> 
                <Button type="button" icon="pi pi-search" class=" p-button p-button-outlined" style="padding:0.65rem" @click="toggleConfigurator"/> 
            </span>
        </div>
    </div>
    <div class="font-normal text-base mb-3">
        <p>Las plantillas de revisión hacen referencia al esquema/lista de revisión que esta se activa para la revisión didáctica segun criterios.
        <a href="javascript:;" class="text-primary">Criterio de evaluación</a></p>
    </div>
    <!--  BEGIN : Form  -->
    <div class="mb-1" v-if="isCreateEsquema">
        <form @submit.prevent="esquema_handleSubmit(!v$.$invalid)" class="p-fluid my-3 border-round p-4">
            <h5 class="font-semibold text-lg">Crear nuevo esquema de revisión</h5>
            <div class="grid">
                <!--Begin: Info data -->
                <div class="col-4">
                    <div class="grid">
                        <div class="col-12">
                            <div class="field">
                                <label for="esq_version">Sección</label>
                                <Dropdown  :class="{'p-invalid': submitted && !seccionSelected}"  :disabled="editMode && seccionSelected != null "
                                    v-model="seccionSelected" :options="seccionList" optionLabel="rse_nombre" optionValue="rse_codigo" placeholder="Seleccione una sección" />
                            </div>
                            
                        </div>
                        <div class="col-12 xl:col-4">
                            <div class="field">
                                <label for="esq_version">Versión</label>
                                <InputNumber id="esq_version" v-model="versionModel.version" prefix="↑ VER " suffix="" :min="1" :max="20"  :disabled="editMode" />
                            </div>
                        </div>
                        <div class="col-12 xl:col-5">
                            <div class="field">
                                <label for="esq_revision">Año Revisión</label>
                                <InputNumber id="esq_anio" v-model="versionModel.revision" showButtons buttonLayout="horizontal" :step="1" :min="2021" :max="2099" :disabled="editMode"
                                decrementButtonClass="p-button-primary" incrementButtonClass="p-button-primary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" :format="false" />
                            </div>
                        </div>
                        <div class="col-12 xl:col-3">
                            <div class="field">
                                <label for="esq_incremental" class="text-sm">Instrumento</label>
                                <InputNumber id="esq_version"  v-model="versionModel.incremental" prefix="↑ " suffix="" :min="1" :max="20"  readonly="true" />
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="field flex flex-column align-items-center">
                                <InputText id="esq_" v-bind:value="versionCode" class="text-center font-bold text-danger " :readonly="true" />
                            </div>
                        </div>
                    </div>
                    <div class="mb-1">
                        <small class="p-error" v-if="submitted && !seccionSelected">* Seleccione una sección.<br></small>
                         <small class="p-error" v-if="submitted && criterioSelected.rce_codigo == 0 ">* Seleccione un criterio.<br></small>
                         <small class="p-error" v-if="submitted && criterioSelected.escalaList.filter(x=> x.rve_selected).length < 2">* Seleccione al menos dos escalas<br></small>
                    </div>
                </div>
                <!--End: Info data -->


                <div class="col-8">
                    <div class="grid">
                        <div class="col-10">
                            <div class="field">
                                <label for="esq_criterio" >Criterio Inicial </label>
                                <div for="esq_criterio" class="p-inputgroup">
                                    <Textarea  placeholder="Criterio de evaluación..." :readonlye="true"  class="font-semibold py-2" :autoResize="true" :rows="3" :value="criterioSelected.rce_enunciado"/>
                                    <Button label="Buscar" @click="openDialogCriterio_onClick()"/>
                                </div>
                            </div>
                        </div>

                        <div class="col-2">
                            <div class="field">
                                <label for="esq_criterio" >Orden </label>
                                <InputNumber id="esq_"  v-model="versionModel.orden_seccion" :inputClass="'text-center font-bold text-danger'" :min="1" :max="100"/>
                            </div>
                        </div>

                        <div class="col-12">
                             <div class="flex justify-content-end">
                                <div class="field-radiobutton">
                                    <Checkbox :id="criterioSelected.rec_codigo + 'blq_init'" :name="criterioSelected.rec_codigo+ 'blq_init'" :binary="true"  v-model="criterioSelected.blq_inicial" />
                                    <label :for="criterioSelected.rec_codigo+ 'blq_init'">Aplica solo a bloque inicial</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <DataTable :value="criterioSelected.escalaList" :reorderableColumns="true" responsiveLayout="scroll" @row-reorder="onRowReorder" selectionMode="single">
                                
                                <template #empty>
                                    <p  class="font-italic text-center">No hay datos que mostrar.</p>
                                </template>
                                <Column :rowReorder="true" headerStyle="width: 8rem" :reorderableColumn="false" >
                                    <template  #body="{index}">
                                       <div class="flex">
                                            <div class="flex justify-content-end">
                                                <a href="javascript:void(0)" class="label label-lg  label-light-primary label-inline font-bold" >{{identificadorNivel(index)}}</a>
                                            </div>
                                       </div>
                                    </template>
                                </Column>
                                <Column field="" header="Escala del criterio" >
                                    <template #body="{data, index}"> 
                                        <div  class="field-radiobutton mt-1">
                                            <Checkbox :id="data.ree_codigo" name="esq_escala" :binary="true" v-model="data.rve_selected"  @input="escala_onSelected($event,index)"/>
                                            <div class="flex flex-column ml-2" >
                                                <label :for="data.ree_codigo">{{data.ree_descripcion}}</label>
                                                <label :for="data.ree_codigo" class="text-xs text-primary">{{data.ree_agrupador}}</label>
                                            </div>
                                        </div>
                                    </template>
                                </Column>
                                <Column field="" header="" headerClass="w-15rem" v-if="Ins == 1 || Ins == 6">
                                    <template #body="{data}"> 
                                        <div class="field-radiobutton">
                                            <Checkbox :id="data.ree_codigo + 'blq_init'" :name="data.ree_codigo + 'blq_init'" :binary="true" :disabled="!data.rve_selected"  v-model="data.rve_req_comentario" />
                                            <label :for="data.ree_codigo + 'blq_init'" class="text-danger">Requiere comentario</label>
                                        </div>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-content">
                <Button type="button" label="Cancelar" class="p-button-text p-button-danger mx-1 " @click="cancelEsquema_onClick()"/>
                <Button  label="Guardar" class="mx-2" type="submit" />
            </div>
        </form>
    </div>
    <!-- END: Form -->

    <div class="mb-1 md:mx-6" v-else>
        <div class="flex justify-content-between font-bold shadow-1 p-2 my-1 ">
            <div class="flex align-items-center">
                <LayoutGrid color="svg-icon-danger" class="mr-2" />
                <span class="text-lg text-danger"> {{esquemaSelected.rve_version}}</span>
            </div>
            <a href="javascript:;" class="hover:bg-blue-100  hover:text-primary p-2" style="border-radius:20px" @click="upsertEsquema_onClick(true)">
                <i class="pi pi-plus-circle"/> Adicionar sección
            </a>
        </div>
        <div class=" md:mx-4 p-datatable p-component p-datatable-responsive-scroll  p-datatable-striped">
            <table class="p-datatable-table table table-light  table-light-success">
                <thead>
                    <tr>
                        <th>Criterio</th>
                        <th width="" class="text-left">Escala</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="esq in revSeccionEsquemaList" :key="esq" >
                        <td  colspan="2">
                            <div class="flex flex-column md:flex-row justify-content-between">
                                <span class="flex">
                                    <h5 class="font-bold  text-lg md:text-xl uppercase">{{esq.seccion}} <small class="text-xs">({{esq.sec_orden}})</small></h5>
                                </span>
                                <a href="javascript:;" class="hover:bg-blue-100  hover:text-primary p-2" style="border-radius:20px" @click="upsertEsquema_onClick(true,esq)">
                                    <i class="pi pi-plus-circle"/> Agregar criterio
                                </a>
                            </div>
                            <div class="grid py-2 pl-3" v-for="(cri,idx) in esq.criterioList" :key="idx" :class="{'bg-gray-100': (idx % 2 != 0)}">
                                <div class="col-12 font-semibold text-gray-800"> 
                                    <span class="font-semibold">{{idx +1}}. </span> {{cri.rce_enunciado}}
                                    <a href="javascript:void(0)" class="label label-lg  label-light-danger label-inline  ml-2 py-3" v-if="cri.blq_inicial">Inicial</a>
                                </div>
                                <div class="col-12 ">
                                    <div class="hover:bg-gray-200 flex justify-content-between align-items-center border-round p-2 md:mx-5 my-1 border-dashed-custom border-gray-400" 
                                    v-for="esc,idxEsc in cri.escalaList" :key="esc">
                                        <div class="flex align-items-center"> 
                                            <i class="pi pi-angle-right text-primary mr-2" /> <span class="font-semibold mr-2">{{vowel(idxEsc)}} </span>{{esc.ree_descripcion}} 
                                        </div>
                                        <span v-if="esc.rve_req_comentario">
                                            <Chip label="Comentario"  icon="pi pi-comments" class="bg-light-primary text-primary"/>
                                        </span>

                                        <div v-if="Ins != 1" class=" flex">
                                            <Chip :label="identificadorNivel(idxEsc)"  icon="pi pi-chart-line" class="bg-light-danger text-danger"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12"> 
                                    <div class="flex justify-content-end">
                                        <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-gray-700 hover:text-primary"  @click="esquemaDelete_onClick(esq,idx)" v-tooltip.top="'Eliminar'" > 
                                            <i class="pi pi-trash"></i> <span class="inline">Eliminar</span>
                                        </a>
                                    </div>
                                </div>
                            </div>                                                   
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class="font-normal text-base mb-3">
        <p class="text-danger">* Si se actualiza la plantilla este afecta histórico. </p>
    </div>
    
    <!--Begin: Sidebar -->
    <div id="layout-config" :class="containerClass">
		<a href="javascript:;" class="layout-config-button bg-primary" id="layout-config-button" @click="toggleConfigurator">
			<i class="pi pi-search"></i>
		</a>
        <Sidebar v-model:visible="visibleVersion" position="right" @hide="visibleVersion = false" class="layout-config-content">
            <h5>Esquemas</h5>
            <DataTable :value="esquemasList" :rowHover="true" stripedRows >
                <Column field="rve_version" header="Versiones">
                    <template #body="{data}">
                        <div class="flex">
                            <div class="h-100 p-0 mr-1 bg-primary" style="width:2px"></div>
                            <div class="flex flex-column align-items-start">
                                <a href="javascript:;" class="font-bold text-gray-800 my-2 hover:text-primary" @click="esquema_onLoad(data)">{{data.rve_version}}</a>
                                <span class="text-muted font-semibold text-sm">{{DateFormat(data.rve_fecha_creacion)}}</span>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </Sidebar>
    </div>
    <!--Begin: Sidebar -->

    <!--Begin: Dialogo Buscar Criterio -->
    <Dialog v-model:visible="isDialogCriterio" :modal="true" :style="{width: '680px'}">
        <template #header>
            <h4 class="font-semibold text-gray-900">Buscar Criterio</h4>
        </template>
        <div class="my-2">
            <CriterioData :Service="Service"  :EditMode="false" :Selection="'single'" v-model:ItemSelected="criterioSelected"  />
        </div>

        <template #footer>
            <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="criterioCancel_onClick()" />
            <Button type="submit" label="Seleccionar" class="mt-2 " @click="criterio_onSelected()" />
        </template>
    </Dialog>
    <!--Begin: Dialogo Buscar Criterio -->

      
    <!--Begin: Dialogo Impresión -->
    <Dialog v-model:visible="isPrintFormat" :modal="true" class="p-dialog-maximized pb-2">
        <template #header>
            <div class="flex">
                <div class="h-100 bg-primary p-0 mr-1" style="width:2px"></div>
                <h5 class="text-primary">FORMATO DE {{instrumento[Ins].toUpperCase()}}</h5>
            </div>
        </template>
        <object :data="printElement" type="application/pdf"  scrolling="auto" class="w-full h-full">
            <embed :src="printElement" type="application/pdf" />
        </object>
    </Dialog> 
    <!--End: Dialogo Impresión -->
</template>


<script>
import CriterioData from "@/components/LaborDocente/Revision/CriterioData"
// eslint-disable-next-line no-unused-vars
import methods from "@/utilities/generalMethods.js"
import { required, helpers, minValue  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { LayoutGrid } from "@/components/svg/_svgHelper"
import ParametroService from "@/service/parametroService"
import moment from 'moment'



    export default{
        parametroService : null,
        components:{
            CriterioData,
            LayoutGrid
        },
        props:{
            Service : null,
            Ins: {
                type: Number,
                default: 0
            }
        },
        emits:[],
        setup: () => ({ v$: useVuelidate() }),
        data(){
            return{
                revSeccionEsquemaList : [],
                esquemasList:[],
                esquemaSelected : {},
                seccionList : [],
                seccionSelected : null,

                criterioSelected: {}, //Criterio a agregar
                                
                versionModel:{
                    version: 1,
                    revision: new Date().getFullYear(),
                    incremental : this.Ins,
                    orden_seccion : 1
                }, // estructura de version

                isDialogCriterio :  false,
                visibleVersion: false,
                isCreateEsquema : false,
                submitted : false,
                editMode : false,
                isPrintFormat : false,
                isloadingPrint : false,
                printElement : null,

                instrumento: [],
                nivelDominioList: []
            }
        },
        validations() {
            return {
                versionModel:{
                    version:{
                        required : helpers.withMessage(
                        () => `*Campo requerido`,
                        required
                        ),
                        minValue: helpers.withMessage(
                            ({
                                $params,
                            }) => `*El campo debería tener un máximo de ${$params.max} `,
                            minValue(1)
                        ),
                    }
                }
            }
        },
        created(){

            //Here get esquema and set
            this.esquemaSelected = {
                rve_version :  "VER1_REV2022-" + this.Ins,
                rve_fecha_creacion : new Date()
            }
            this.fx_Init()

            this.instrumento[1] = "Esquema de Revisión";
            this.instrumento[3] = "Guía de observación del desempeño docente (GODD)"
            this.instrumento[4] = "Escala de estimación del desempeño docente (EEDD)"
            this.instrumento[6] = "Esquema Desarrollo y Rendimiento (EDRE)"

            this.parametroService =  new ParametroService(this.axios)
        },
        async mounted(){

            this.fx_getEsquema();
            
        },
        methods:{
            async fx_getEsquema(){
                this.revSeccionEsquemaList   = await this.Service.getEsquema(this.esquemaSelected.rve_version)
                var version =  (this.esquemaSelected.rve_version.split("_")[0])[3] // VER1 -> Example
              
                //this.correlativo =  1
                if(this.revSeccionEsquemaList.length > 0)
                    this.nivelDominioList =  await this.parametroService.getNivelDominio(version)
            },
            fx_Init(){
                //this is the structure for manipulate data 
                this.criterioSelected = {
                    rce_codigo :  0,
                    rce_enunciado : "",
                    rce_activo : false,
                    blq_inicial : false,
                    escalaList : [
                        /*{
                            ree_codigo:  0,
                            ree_descripcion : "",
                            rve_req_comentario : "",
                            rve_codrec : 0,// for cri-esc
                            rve_selected: false // for checkbox
                        }*/
                    ]
                }
            },

            async esquema_onLoad(data){
                this.esquemaSelected =  data;
                this.visibleVersion =  false
                this.fx_getEsquema();

                this.isCreateEsquema = false
            },
            cancelEsquema_onClick(){
                this.versionModel = {
                    version: 0,
                    revision: new Date().getFullYear(),
                    incremental : this.Ins,
                    orden_seccion : 1
                };

               
                this.fx_Init();
                this.isCreateEsquema =  false

            },

            async esquemaDelete_onClick(data, criIdx){
                //data : Esquema 
                //criIdx: Index de criterio
               this.$confirm.require({
                    message: `¿Está seguro de eliminar este criterio del esquema?`,
                    header: 'Eliminar Esquema',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: async () => {
                        try{
                            var message ="";
                            await this.Service.deleteEsquema({
                                rve_codrse : data.codseccion,
                                rve_codrec : data.criterioList[criIdx].rce_codigo,
                                rve_version : data.version
                            }).then(res  => message = res)
                            this.$toast.add({severity:'info', summary:'¡Eliminado!', detail:message, life: 3000});
                            this.fx_getEsquema();
                        }catch(ex){
                            var msg =  ex;
                            if(msg.response)
                                msg= msg.response.data;

                            this.$toast.add({severity:'error', summary:'¡Ocurrió un error!', detail:msg, life: 3000});
                        }
                    },
                });
            },

            async upsertEsquema_onClick(editMode =  false, esq = null){
                this.seccionList =  await this.Service.getAllSeccion();
                this.fx_Init();
                this.editMode = editMode
                this.seccionSelected =  !esq  ? null : esq.codseccion
                
                
                if(editMode)
                {
                    //Setting the Version Code.
                    var orden = esq != null ?  esq.sec_orden : this.revSeccionEsquemaList.length + 1
                    //Example rve_version => VER1_REV2022-1
                    var codeSplit =  this.esquemaSelected.rve_version.split("_");
                    var codeRev = codeSplit[1].split("-");
                    this.versionModel  = {
                        version: Number(codeSplit[0].split("VER").pop()),
                        revision: Number(codeRev[0].split("REV").pop()),
                        incremental: Number(codeRev[1]),
                        orden_seccion: orden
                    }
                }
                this.isCreateEsquema =  true;
            },

            async esquema_handleSubmit(isFormValid){
                try{
                    this.submitted = true;
                    if (!isFormValid) {
                        return;
                    }
                    
                    //preparando envio.
                    var escalas = this.criterioSelected.escalaList.filter(x=> x.rve_selected)
                    if(escalas.length < 2){
                        return;
                    }else if(!this.seccionSelected){
                        return;
                    }                  

                    //Preparando datos
                    var esquemaData =  [];
                    this.criterioSelected.escalaList.forEach(element =>{
                        if(element.rve_selected)
                            esquemaData.push({
                                rve_codrse :  this.seccionSelected,
                                rve_codrec :  element.rve_codrec,
                                rve_req_comentario : element.rve_req_comentario,
                                rve_blq_inicial :  this.criterioSelected.blq_inicial,
                                rve_sec_orden : this.versionModel.orden_seccion,
                                rve_version : this.versionCode
                            })
                    })

                    this.esquemaSelected.rve_version =   await this.Service.createEsquema(esquemaData);
                    this.$toast.add({severity:'info', summary:'¡Éxito!', detail:"Esquema creado con éxito", life: 5000});
                    this.fx_getEsquema();
                    this.isCreateEsquema =  false;
                    this.submitted =  false;

                }catch(ex){
                    var msg =  ex;
                    if(msg.response)
                        msg = msg.response.data
                    this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
                }
            },

            async printEsquema_onClick(){
                try {
                    this.isloadingPrint =  true
                    await this.Service.printEsquema(this.esquemaSelected.rve_version).then(res  =>{
                        const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
                        this.printElement  = url + `#filename=${this.esquemaSelected.rve_version}.pdf`
                    })
                    this.isPrintFormat =  true
                }catch(ex){
                    this.$toast.add({severity:'info', summary:'¡Ocurrió un error!', detail:ex, life: 3000});
                }finally{
                    this.isloadingPrint =  false
                }
            },
            
            openDialogCriterio_onClick(){
                this.isDialogCriterio =  true;
            },
            async criterio_onSelected(){
                try{
                    this.criterioSelected["escalaList"] = [];
                    var  criterioEscalaList =  await this.Service.getAllCriterioEscala(this.criterioSelected.rce_codigo) //get Criterio and Escala Properties
                    .then(res =>{
                        var newData = []
                        res.forEach(element => {
                            element.escala["rve_selected"] = false //add properties for select
                            element.escala["rve_codrec"] = element.rec_codigo // add property for Criterio Escala
                            newData.push(element.escala);
                        });
                        return  newData;
                   });
                   
                    this.criterioSelected["escalaList"] =  criterioEscalaList
                }catch(ex){
                    var msg =  ex;
                    this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
                }finally{
                    this.isDialogCriterio =  false;
                }
            },
            criterioCancel_onClick(){
                this.isDialogCriterio =  false; 
                this.fx_Init();
            },
            escala_onSelected(event, index){
               if(!event){ //because the value is binary
                   this.criterioSelected.escalaList[index].rve_req_comentario =  false;
               }
            },
            identificadorNivel(index){
                if((index + 1) <= this.nivelDominioList.length )
                {
                    return this.nivelDominioList[4-index].nid_nombre
                }
                return "N/D"
            },
            async toggleConfigurator(event) {
				this.visibleVersion = !this.visibleVersion;
                this.esquemasList  = await this.Service.getAllEsquema(this.Ins);
				event.preventDefault();
			},

            onRowReorder(event) {
                //update new order
                this.criterioSelected.escalaList = event.value;
            },

            DateFormat(data) {
                return moment(data).format('DD/MM/YYYY')
            },
            vowel(idx){
                return methods.integerToVowel(idx);
            }
           
           
        },
        computed:{
            containerClass() {
				return ['layout-config', {'layout-config-active': this.visibleVersion}];
			},
            versionCode(){
                return `VER${this.versionModel.version}_REV${this.versionModel.revision}-${this.versionModel.incremental}`
            },
            
        },
       
    }
</script>

<style scoped>
    .table-light, .table-light > th, .table-light > td {
        background-color: #FCFCFD;
    }
    .table.table-light-success thead tr {
        color: #3699FF;
        background-color: rgba(54, 153, 255, 0.1);
    }

    .table thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #EBEDF3;
    }
    .table thead th, .table thead td {
        font-weight: 600; 
        font-size: 1rem; 
        border-bottom-width: 1px; 
    }
    .table th, .table td {
        padding:0.375rem 0.75rem !important;
        vertical-align: top !important;
        border-top: 1px solid #EBEDF3 !important;
    }
</style>