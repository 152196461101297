<template>
    <div class="flex align-items-center justify-content-between flex-wrap">
        <h4 class="font-bold mb-5 text-gray-800 hover:text-primary">Escalas de Revisión</h4>
        <div class="my-1">
            <Button type="button" icon="pi pi-plus" label="Nuevo" class=" p-button-outlined" @click="upsertEscala_onClick()"/> 
        </div>
    </div>
    <div class="font-normal text-base mb-3">
        <p>Las escalas de evaluación para una revisión son agupadas para segmentarlas en los 
        <a href="javascript:;" class="text-primary">Criterios de evaluación</a>. Si se necesita agregar otro grupo lo puede detallar en la creación. </p>
    </div>
    <div class="mb-5">
        <EscalaData :Service="Service" @edit_onEmit="upsertEscala_onClick($event)" :key="escalaDataKey"/>
    </div>
        <div class="font-normal text-base mb-3">
        <p class="text-danger">* Si se actualiza la descripción de uno o más grupos este afecta el histórico. </p>
    </div>

    <EscalaUpsert :Service="Service" :value="escalaModel" v-model:Upsert="upsertMode"  @hide="escala_onHide($event)" :key="upsertEscalaKey"/>
    
</template>




<script>
import EscalaData from "@/components/LaborDocente/Revision/EscalaData"
import EscalaUpsert from "@/components/LaborDocente/Revision/EscalaUpsert"



export default {
    components:{
        EscalaData,
        EscalaUpsert
    },
    props:{
        Service:null
    },
    emits:[],
    data(){

        return  {
            escalaModel : {},
            upsertMode: false,


            upsertEscalaKey: 0,
            escalaDataKey: 0
        }
    },
    created(){
        this.escalaModel =   {
            ree_codigo :  0,
            ree_descripcion : "",
            ree_agrupador:  ""
        }
    },
    mounted(){},
    methods:{

        escala_onHide(event){
            this.upsertMode = false
            if(event != null) this.escalaDataKey += 1;

        },
        upsertEscala_onClick(data = null){
            if(data == null)
                this.escalaModel =   {
                    ree_codigo :  0,
                    ree_descripcion : "",
                    ree_agrupador:  ""
                }
            else{
                this.escalaModel =  data
            }
            this.upsertEscalaKey += 1; // force render
            this.upsertMode =  true;
        }


    },
    computed:{
       
    }
}
</script>