<template>
    <div class="flex flex-column md:flex-row justify-content-end align-items-center mb-2">
        <span class="p-input-icon-left  p-fluid" >
            <i class="pi pi-search" />
            <InputText  placeholder="Buscar nombre"   v-model="filters1['global'].value"/>
        </span>
    </div>
    <DataTable :value="seccionList" responsiveLayout="scroll" :rowHover="true" :rows="20" class="p-datatable-sm"
        v-model:selection="seccionSelected" :selectionMode="Selection" dataKey="rse_codigo"
        :globalFilterFields="['rse_nombre', 'rse_codigo']" v-model:filters="filters1">
        <template #loading>
            Cargando secciones, por favor espere...
        </template>
        <template #empty>
            No hay secciones en la base de datos.
        </template>
            <Column field="rse_codigo"  header="Cód." headerStyle="width:4rem">
            <template #body="{data}"> S{{data.rse_codigo}}
            </template>
        </Column>
        <Column field="rse_nombre" header="Descripcion"/>
        <Column  header="">
            <template #body="{data}">
                <a href="javascript:void(0)" class="label label-lg label-inline font-bold ml-2 py-0 my-0" :class="'label-light-'+ (data.rse_activo ? 'primary' : 'danger')">{{data.rse_activo ? "Activo" : "Inactivo"}}</a>
            </template>
        </Column>
        <Column  v-if="EditMode">
            <template #body="{data}">
                <div class="flex justify-content-end flex-shrink-0">
                    <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary" @click="edit_onClick(data)" v-tooltip.top="'Editar'" > 
                        <i class="pi pi-pencil"></i>
                    </a>
                </div>
            </template>
        </Column>

    </DataTable>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
export default {
    props:{
        Service: null,
        EditMode:{
            type:Boolean,
            default: true
        },
        Selection: {
            type:String,
            default : null
        },
        ItemSelected:{
            type: Object,
            default(){
                return {}
            }
        }
    },
    emits: ["edit_onEmit",'update:ItemSelected'],
    data(){
        return{
            seccionList: [],
            seccionSelected: this.ItemSelected,
            filters1: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
    },
    created(){},
    async mounted(){
        this.seccionList =  await  this.Service.getAllSeccion();

    },
    methods:{
        edit_onClick(data){
            this.$emit("edit_onEmit", data)
        }
    },
    watch:{
        // eslint-disable-next-line no-unused-vars
        escalaSelected: function(val){
           this.$emit('update:ItemSelected', val)
        }
    }
}
</script>